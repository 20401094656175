import Repository from './repository'

const resource = '/kpi'

export default {
  DashboardPreviewPost (data) {
    return Repository.post(`${resource}/student-performance-board-preview`, data)
  },
  DashboardRealizesPost (data) {
    return Repository.post(`${resource}/student-performance-board-realized`, data)
  },
  DashboardIndicatorsGet () {
    return Repository.get(`${resource}/coach-indicators`)
  }
}
