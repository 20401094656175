<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-3 col-sm-6 mt-sm-0">
        <card
          title="Total de alunos"
          :value="indicators['total_students']"
          percentage=""
          iconClass="ni ni-single-02"
          :iconBackground="stats.iconBackground"
          directionReverse
        >
        </card>
      </div>
      <div class="col-lg-3 col-sm-6 mt-sm-0">
        <card
          title="Alunos masculino"
          :value="indicators['total_students_m']"
          percentage=""
          iconClass="ni ni-single-02"
          :iconBackground="stats.iconBackground"
          directionReverse
        >
        </card>
      </div>
      <div class="col-lg-3 col-sm-6 mt-sm-0">
        <card
            title="Alunos feminino"
            :value="indicators['total_students_f']"
            percentage=""
            iconClass="ni ni-single-02"
            :iconBackground="stats.iconBackground"
            directionReverse
        >
        </card>
      </div>
      <div class="col-lg-3 col-sm-6 mt-sm-0">
        <card
            title="Total de treinos"
            :value="indicators['total_trainings']"
            percentage=""
            iconClass="ni ni-single-02"
            :iconBackground="stats.iconBackground"
            directionReverse
        >
        </card>
      </div>
      <div class="col-6 mt-sm-0">
        <card
          title="Total de alunos em dia"
          value="77"
          percentage="88"
          iconClass="ni ni-single-02"
          :iconBackground="stats.iconBackground"
          percentageColor="text-danger"
          directionReverse
        >
        </card>
      </div>
      <div class="col-6 mt-sm-0">
        <card
          title="Total de alunos em atraso"
          value="10"
          percentage="10"
          iconClass="ni ni-single-02"
          :iconBackground="stats.iconBackground"
          percentageColor="text-danger"
          directionReverse
        >
        </card>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card pb-5">
          <div class="p-3 pb-0">
            <div class="row justify-content-center">
              <div class="col-12 text-center">
                Quadro comparativo de evolução
              </div>
              <div class="col-6">
                <label class="form-label mt-2 text-center">Selecione o aluno</label>
                <select class="form-control" name="dashboard-alunos" id="dashboard-alunos" v-model="alunoSelecionado" @change="getDashboardData">
                  <option value="" selected="selected">Selecione aqui</option>
                  <option v-for="(item, index) in alunos" :key="index" :value="item.id">{{ item.name }}</option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-12 d-flex justify-content-center align-items-end">
                <div class="mx-2">
                  <span>
                    <i class="fa fa-arrow-left" @click="voltarData"></i>
                  </span>
                </div>
                <div class="mx-2">
                  <label class="form-label mt-2">Mês referência</label>
                  <select class="form-control form-control-sm custom-select" v-model="mesSelecionado" @change="getDashboardData">
                    <option v-for="(item, index) in stats.months" :key="index" :value="item.numero" selected>{{ item.mes }}</option>
                  </select>
                </div>
                <div class="mx-2">
                  <label class="form-label mt-2">Ano referência</label>
                  <select class="form-control form-control-sm custom-select" v-model="anoSelecionado" @change="getDashboardData">
                    <option value="2023" selected>2023</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                    <option value="2026">2026</option>
                    <option value="2027">2027</option>
                    <option value="2028">2028</option>
                    <option value="2029">2029</option>
                    <option value="2030">2030</option>
                  </select>
                </div>
                <div class="mx-2">
                  <span>
                    <i class="fa fa-arrow-right" @click="avancarData"></i>
                  </span>
                </div>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-12 table-responsive">
                <table class="table table-bordered table-striped">
                  <thead>
                    <tr class="text-center">
                      <th></th>
                      <th colspan="3" class="font-weight-bold">Previsto</th>
                      <th colspan="3" class="font-weight-bold">Realizado</th>
                    </tr>
                    <tr>
                      <th></th>
                      <th>Mês</th>
                      <th>Ano {{ anoSelecionado }}</th>
                      <th>Acumulado</th>
                      <th>Mês</th>
                      <th>Ano {{ anoSelecionado }}</th>
                      <th>Acumulado</th>
                    </tr>
                  </thead>
                  <tbody v-if="dashboardItemEstimado != null">
                    <tr>
                      <td class="font-weight-bold">Tempo Total de Treino</td>
                      <td>{{dashboardItemEstimado['psr_pse']['tempo']['mes']}}</td>
                      <td>{{dashboardItemEstimado['psr_pse']['tempo']['ano']}}</td>
                      <td>{{dashboardItemEstimado['psr_pse']['tempo']['acumulado']}}</td>
                      <td>{{dashboardItem['psr_pse']['tempo']['mes']}}</td>
                      <td>{{dashboardItem['psr_pse']['tempo']['ano']}}</td>
                      <td>{{dashboardItem['psr_pse']['tempo']['acumulado']}}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">N. de Treino Realizados</td>
                      <td>{{dashboardItemEstimado['treinos']['realizados']['mes']}}</td>
                      <td>{{dashboardItemEstimado['treinos']['realizados']['ano']}}</td>
                      <td>{{dashboardItemEstimado['treinos']['realizados']['acumulado']}}</td>
                      <td>{{dashboardItem['treinos']['realizados']['mes']}}</td>
                      <td>{{dashboardItem['treinos']['realizados']['ano']}}</td>
                      <td>{{dashboardItem['treinos']['realizados']['acumulado']}}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">N. de Treinos Não Realiz.</td>
                      <td>{{dashboardItemEstimado['treinos']['nao_realizados']['mes']}}</td>
                      <td>{{dashboardItemEstimado['treinos']['nao_realizados']['ano']}}</td>
                      <td>{{dashboardItemEstimado['treinos']['nao_realizados']['acumulado']}}</td>
                      <td>{{dashboardItem['treinos']['nao_realizados']['mes']}}</td>
                      <td>{{dashboardItem['treinos']['nao_realizados']['ano']}}</td>
                      <td>{{dashboardItem['treinos']['nao_realizados']['acumulado']}}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">N. Total de Repetições</td>
                      <td>{{dashboardItemEstimado['dados_treinos']['repeticao']['mes']}}</td>
                      <td>{{dashboardItemEstimado['dados_treinos']['repeticao']['ano']}}</td>
                      <td>{{dashboardItemEstimado['dados_treinos']['repeticao']['acumulado']}}</td>
                      <td>{{dashboardItem['dados_treinos']['repeticao']['mes']}}</td>
                      <td>{{dashboardItem['dados_treinos']['repeticao']['ano']}}</td>
                      <td>{{dashboardItem['dados_treinos']['repeticao']['acumulado']}}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">N. Total de Séries</td>
                      <td>{{dashboardItemEstimado['dados_treinos']['serie']['mes']}}</td>
                      <td>{{dashboardItemEstimado['dados_treinos']['serie']['ano']}}</td>
                      <td>{{dashboardItemEstimado['dados_treinos']['serie']['acumulado']}}</td>
                      <td>{{dashboardItem['dados_treinos']['serie']['mes']}}</td>
                      <td>{{dashboardItem['dados_treinos']['serie']['ano']}}</td>
                      <td>{{dashboardItem['dados_treinos']['serie']['acumulado']}}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">N. Total de Tonelagem</td>
                      <td>{{dashboardItemEstimado['dados_treinos']['tonelagem']['mes']}}</td>
                      <td>{{dashboardItemEstimado['dados_treinos']['tonelagem']['ano']}}</td>
                      <td>{{dashboardItemEstimado['dados_treinos']['tonelagem']['acumulado']}}</td>
                      <td>{{dashboardItem['dados_treinos']['tonelagem']['mes']}}</td>
                      <td>{{dashboardItem['dados_treinos']['tonelagem']['ano']}}</td>
                      <td>{{dashboardItem['dados_treinos']['tonelagem']['acumulado']}}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">PSE Média</td>
                      <td>{{dashboardItemEstimado['psr_pse']['pse']['mes']}}</td>
                      <td>{{dashboardItemEstimado['psr_pse']['pse']['ano']}}</td>
                      <td>{{dashboardItemEstimado['psr_pse']['pse']['acumulado']}}</td>
                      <td>{{dashboardItem['psr_pse']['pse']['mes']}}</td>
                      <td>{{dashboardItem['psr_pse']['pse']['ano']}}</td>
                      <td>{{dashboardItem['psr_pse']['pse']['acumulado']}}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">PSR Média</td>
                      <td>{{dashboardItemEstimado['psr_pse']['psr']['mes']}}</td>
                      <td>{{dashboardItemEstimado['psr_pse']['psr']['ano']}}</td>
                      <td>{{dashboardItemEstimado['psr_pse']['psr']['acumulado']}}</td>
                      <td>{{dashboardItem['psr_pse']['psr']['mes']}}</td>
                      <td>{{dashboardItem['psr_pse']['psr']['ano']}}</td>
                      <td>{{dashboardItem['psr_pse']['psr']['acumulado']}}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">Média de Autoridade Arb.</td>
                      <td> - </td>
                      <td> - </td>
                      <td> - </td>
                      <td>{{dashboardItem['psr_pse']['autoridade']['mes']}}</td>
                      <td>{{dashboardItem['psr_pse']['autoridade']['ano']}}</td>
                      <td>{{dashboardItem['psr_pse']['autoridade']['acumulado']}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-12 col-md-10">
        <div class="card">
          <div class="p-3 pb-0 card-header">
            <div class="d-flex justify-content-between">
              <h6 class="mb-2">Sales by Country</h6>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table align-items-center">
              <tbody>
                <tr v-for="(sale, index) in sales" :key="index">
                  <td class="w-30">
                    <div class="px-2 py-1 d-flex align-items-center">
                      <div>
                        <img :src="sale.flag" alt="Country flag" />
                      </div>
                      <div class="ms-4">
                        <p class="mb-0 text-xs font-weight-bold">
                          Country:
                        </p>
                        <h6 class="mb-0 text-sm">{{ sale.country }}</h6>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="text-center">
                      <p class="mb-0 text-xs font-weight-bold">Sales:</p>
                      <h6 class="mb-0 text-sm">{{ sale.sales }}</h6>
                    </div>
                  </td>
                  <td>
                    <div class="text-center">
                      <p class="mb-0 text-xs font-weight-bold">Value:</p>
                      <h6 class="mb-0 text-sm">{{ sale.value }}</h6>
                    </div>
                  </td>
                  <td class="text-sm align-middle">
                    <div class="text-center col">
                      <p class="mb-0 text-xs font-weight-bold">Bounce:</p>
                      <h6 class="mb-0 text-sm">{{ sale.bounce }}</h6>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div> -->
    <!-- <div class="mt-4 row">
      <div class="mb-4 col-lg-5 mb-lg-0">
        <div class="card z-index-2">
          <div class="p-3 card-body">
            
            <active-users-chart />
          </div>
        </div>
      </div>
      <div class="col-lg-7">
        <div class="card z-index-2">
          <gradient-line-chart />
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import Card from "../../../components/Cards/Card.vue";
import ActiveUsersChart from "../../../components/Charts/ActiveUsersChart.vue";
import GradientLineChart from "../../../components/Charts/GradientLineChart.vue";

import US from "../../../assets/img/icons/flags/US.png";
import DE from "../../../assets/img/icons/flags/DE.png";
import GB from "../../../assets/img/icons/flags/GB.png";
import BR from "../../../assets/img/icons/flags/BR.png";
import moment from "moment/moment";
import dashboardRepository from "@/services/api/dashboardRepository";
import alunoRepository from "@/services/api/alunoRepository";

export default {
  name: "dashboard-default",
  created() {
    console.log('dashboardItemEstimado')
    console.log(this.dashboardItemEstimado)
    this.listarAlunos()
    if(this.alunoSelecionado !== "" && this.alunoSelecionado !== null) {
      this.getDashboardData()
    }
    this.getIndicators()

  },
  data() {
    return {
      mesSelecionado: moment().format('MM'),
      anoSelecionado: moment().format('YYYY'),
      alunoSelecionado: "",
      alunos: [],
      stats: {
        months: [
          { mes: "Janeiro", numero: '01' },
          { mes: "Fevereiro", numero: '02' },
          { mes: "Março", numero: "03" },
          { mes: "Abril", numero: '04' },
          { mes: "Maio", numero: '05' },
          { mes: "Junho", numero: '06' },
          { mes: "Julho", numero: '07' },
          { mes: "Agosto", numero: '08' },
          { mes: "Setembro", numero: '09' },
          { mes: "Outubro", numero: '10' },
          { mes: "Novembro", numero: '11' },
          { mes: "Dezembro", numero: '12' }
        ],
        dataFiltro: moment().format('YYYY-MM'),

        iconBackground: "bg-gradient-success",


      },
      dashboardItemEstimado: null,
      dashboardItem: null,
      indicators: []
    };
  },
  methods: {
    listarAlunos() {
      alunoRepository.AlunoGet().then(response => {
        this.alunos = []
        if (response.data.success) {
          this.alunos = response.data.data
        }
      }).catch((error) => {
        console.error(error)
      })
    },
    avancarData() {
      this.stats.dataFiltro = moment(this.stats.dataFiltro).add(1, 'M').format('YYYY-MM')

      const dataItem = this.stats.dataFiltro.split('-')
      const res = this.stats.months.find(month => month.numero === dataItem[1]);
      this.mesSelecionado = res.numero

      this.anoSelecionado = dataItem[0]

      this.getDashboardData()
    },
    voltarData() {
      this.stats.dataFiltro = moment(this.stats.dataFiltro).subtract(1, 'months').format('YYYY-MM')

      const dataItem = this.stats.dataFiltro.split('-')
      const res = this.stats.months.find(month => month.numero === dataItem[1]);
      this.mesSelecionado = res.numero

      this.anoSelecionado = dataItem[0]

      this.getDashboardData()
    },
    getIndicators() {
      dashboardRepository.DashboardIndicatorsGet().then(response => {
        console.log(response.data)
        if(response.data.success) {
          this.indicators = response.data.data
        }
      })
    },
    getDashboardData() {
      const item = {
        student_id: this.alunoSelecionado,
        year: this.anoSelecionado,
        month: this.mesSelecionado
      }
      dashboardRepository.DashboardPreviewPost(item).then(response => {
        console.log(response.data)
        if(response.data.success) {
          this.dashboardItemEstimado = response.data.data
        }
      })

      dashboardRepository.DashboardRealizesPost(item).then(response => {
        console.log(response.data)
        if(response.data.success) {
          this.dashboardItem = response.data.data
        }
      })


    }
  },
  computed: {
    /*mesSelecionado() {
      const dataItem = this.stats.dataFiltro.split('-')
      const res = this.stats.months.find(month => month.numero === dataItem[1]);

      return res.numero
    },
    anoSelecionado() {
      const dataItem = this.stats.dataFiltro.split('-')

      return dataItem[0]
    }*/
  },
  components: {
    Card,
    ActiveUsersChart,
    GradientLineChart,
  },
};
</script>
